<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="testItems"
      :hide-default-footer="false"
      :item-class="getRowClass"
      @click:row="handleRowClick"
    >
      <template v-slot:item.gender="{}">
        <div style="width: 48px" class="d-flex align-items py-2">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0.3 23.3 47.8 48"
            xml:space="preserve"
          >
            <Female55Avatar />
          </svg>
        </div>
      </template>

      <template v-slot:item.score="{ item }">
        <div class="font-weight-bold">
          {{ item.score }}<br />
          <v-btn
            href="https://dev.riskscore.us/your-score-report/61727be4-5613-43dd-948f-d0fb9f866cd8"
            target="_blank"
            text
            x-small
            color="primary"
            >View Report</v-btn
          >
        </div>
      </template>

      <template v-slot:item.profile="{}">
        <v-btn to="prospect/61727be4-5613-43dd-948f-d0fb9f866cd8" small text
          >View Profile
        </v-btn>
      </template>

      <!-- <template v-slot:item.actions="{}">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark icon v-bind="attrs" v-on="on">
              <v-icon small> mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Funnel</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="grey" dark icon v-bind="attrs" v-on="on">
              <v-icon small class="mr-2"> mdi-email </v-icon>
            </v-btn>
          </template>
          <span>Email</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="red" dark icon v-bind="attrs" v-on="on">
              <v-icon small> mdi-archive-arrow-down-outline</v-icon>
            </v-btn>
          </template>
          <span>Archive</span>
        </v-tooltip>
      </template> -->
    </v-data-table>
  </div>
</template>

<script>
import Female55Avatar from "@/components/reportAnimationAvatars/Female55Avatar";

export default {
  components: {
    Female55Avatar,
  },
  data() {
    return {
      headers: [
        {
          text: "Gender",
          align: "start",
          sortable: true,
          value: "gender",
        },
        {
          text: "Age",
          align: "start",
          sortable: true,
          value: "age",
        },
        { text: "Risk Score", align: "center", value: "score" },
        { text: "Likelihood (%)", align: "center", value: "likelihood" },
        { text: "Profile", align: "center", sortable: false, value: "profile" },
        {
          text: "Recommendations",
          align: "center",
          sortable: true,
          value: "funnel",
        },
        // { text: "Actions", align: "center", sortable: false, value: "actions" },
      ],
      testItems: [
        {
          gender: "male",
          age: 56,
          score: 78,
          likelihood: 41,
          temp: "hot",
          funnel: "Stage 1",
        },
        {
          gender: "female",
          age: 30,
          score: 81,
          likelihood: 33,
          temp: "warm",
          funnel: "Stage 3",
        },
        {
          gender: "female",
          age: 30,
          score: 81,
          likelihood: 33,
          temp: "cold",
          funnel: "Stage 2",
        },
      ],
    };
  },
  methods: {
    handleRowClick(item) {
      console.log("row click", item);
    },
    getRowClass(item) {
      let color = "rs-table-row ";
      switch (item.temp) {
        case "hot":
          color += "rs-table-row--hot";
          break;
        case "warm":
          color += "rs-table-row--warm";
          break;
        case "cold":
          color += "rs-table-row--cold";
          break;
        default:
          break;
      }

      return color;
    },
  },
};
</script>

<style lang="scss">
.rs-table-row {
  td:first-child {
    border-left: 4px solid;
  }

  & {
    &--hot {
      td:first-child {
        border-left-color: #e15241;
      }
      &:hover {
        background-color: rgba(#e15241, 0.05) !important;
      }
    }
    &--warm {
      td:first-child {
        border-left-color: #f19d38;
      }
      &:hover {
        background-color: rgba(#f19d38, 0.05) !important;
      }
    }
  }
  &--cold {
    td:first-child {
      border-left-color: #54b9d1;
    }
    &:hover {
      background-color: rgba(#54b9d1, 0.05) !important;
    }
  }
}
</style>
